<template>
  <div class="center">
    <va-button class="mb-4 mr-4 mybutton" color="#454545" text-color="#FFFFFF" @click="SignOut"> Log Out </va-button>
  </div>
</template>


<script>
import {session} from '../js/storage'
export default {
    name: 'Logout',
    methods: {
      async SignOut() {
        var msalInstance=session.get("msalInstance");
        console.log(this.$msalInstance);
        await msalInstance.logout()
          .then(() => {
            this.$emitter.emit('loginStatus', 'loggedout');
            session.set("authenticated", false);
            session.set("name", "");
            session.set("accessToken", "");
            session.set("idToken", "");
            session.set("loginResponse", "");
            //router.push("Login");
          })
          .catch(error => {
            console.error(error);
          });
      },
    },
}
</script>

<style>
.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.auth-container {
  display: "block" !important;
}


</style>